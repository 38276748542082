<template>
    <div class="card card-widget">
        <!-- Add the bg color to the header using any of the bg-* classes -->
        <div class="card-footer p-0">
            <ul class="nav flex-column">
            <li class="nav-item active">
                <a href="#" class="nav-link">
                Activos <span class="float-right badge bg-primary">2</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="#" class="nav-link">
                Eliminados <span class="float-right badge bg-info">5</span>
                </a>
            </li>            
            </ul>
        </div>
    </div>
</template>